import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import './css/Navbar.css';

const Navbar = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.pathname);

    const handleClick = (path) => {
        if (location.pathname === path) {
            // Force page reload if already on the same page
            window.location.reload();
        } else {
            setActiveTab(path);
        }
    };

    return (
        <div className="navbar">
            <div className="navbar-content">
                <Link
                    to="/feed"
                    className={`navbar-item ${(activeTab === '/feed' || activeTab === '/') ? 'active' : ''}`}
                    onClick={() => handleClick('/feed')}
                >
                    <HomeIcon className="feed-icon" />
                    <span className={`label ${(activeTab === '/feed' || activeTab === '/')? 'active' : ''}`}>Feed</span>
                </Link>
                <Link
                    to="/wordle"
                    className={`navbar-item ${activeTab === '/wordle' ? 'active' : ''}`}
                    onClick={() => handleClick('/wordle')}
                >
                    <img src='/assets/wordle-icon-256-removebg-preview.png' alt="Wordle" className="wordle-icon" />
                    <span className={`label ${activeTab === '/wordle' ? 'active' : ''}`}>Wordle</span>
                </Link>
                <Link
                    to="/leaderboard"
                    className={`navbar-item ${activeTab === '/leaderboard' ? 'active' : ''}`}
                    onClick={() => handleClick('/leaderboard')}
                >
                    <EmojiEventsIcon className="nav-icon" />
                    <span className={`label ${activeTab === '/leaderboard' ? 'active' : ''}`}>Streak</span>
                </Link>
            </div>
        </div>
    );
};

export default Navbar;
