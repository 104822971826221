export const keys = [
  { "key": "Q" },
  { "key": "W" },
  { "key": "E" },
  { "key": "R" },
  { "key": "T" },
  { "key": "Y" },
  { "key": "U" },
  { "key": "I" },
  { "key": "O" },
  { "key": "P" },
  { "key": "A" },
  { "key": "S" },
  { "key": "D" },
  { "key": "F" },
  { "key": "G" },
  { "key": "H" },
  { "key": "J" },
  { "key": "K" },
  { "key": "L" },
  { "key": "Z" },
  { "key": "X" },
  { "key": "C" },
  { "key": "V" },
  { "key": "B" },
  { "key": "N" },
  { "key": "M" }
];
