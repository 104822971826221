import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { backendDomain } from '../constants';
import Navbar from '../components/Navbar';
import { useSelector } from 'react-redux';
import './css/LeaderboardPage.css';
import StaticNavHeader from '../components/StaticNavHeader';

const LeaderboardTable = ({ data, type }) => {
    // Get current user ID from Redux store instead of localStorage
    const currentUser = useSelector((state) => state.user);
    const currentUserId = currentUser._id;

    return (
        <div className="leaderboard-table">
            <div className="table-header">
                <div className="rank-column">Rank</div>
                <div className="name-column">Name</div>
                <div className="streak-column">🔥</div>
            </div>
            <div className="table-body">
                {data.map((user, index) => (
                    <div 
                        key={user._id} 
                        className={`table-row ${user._id === currentUserId ? 'current-user' : ''}`}
                        onClick={() => window.location.href = `/profile/${user._id}`}
                    >
                        <div className="rank-column">{index + 1}</div>
                        <div className="name-column">
                            <img src={user.imageUri} alt={`${user.firstName} ${user.lastName}`} className="user-avatar" />
                            <span>{`${user.firstName} ${user.lastName}`}</span>
                        </div>
                        <div className="streak-column">{type === 'current' ? user.currentStreak : user.maxStreak}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const LeaderboardPage = () => {
    const [tabValue, setTabValue] = useState(0);
    const [currentStreakData, setCurrentStreakData] = useState([]);
    const [maxStreakData, setMaxStreakData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [currentResponse, maxResponse] = await Promise.all([
                    fetch(`${backendDomain}/wordle/leaderboard/currentstreak`),
                    fetch(`${backendDomain}/wordle/leaderboard/maxstreak`)
                ]);

                const currentData = await currentResponse.json();
                const maxData = await maxResponse.json();

                setCurrentStreakData(currentData);
                setMaxStreakData(maxData);
            } catch (error) {
                console.error('Error fetching leaderboard data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    if (loading) {
        return (
            <>
                <StaticNavHeader />
                <div className="leaderboard-loading">Loading...</div>
                <Navbar />
            </>
        );
    }

    return (
        <>
            <StaticNavHeader />
            <div className="leaderboard-page">
                <div className="tabs-container">
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs 
                            value={tabValue} 
                            onChange={handleTabChange} 
                            centered
                            sx={{
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#1976d2'
                                },
                                '& .MuiTab-root': {
                                    '&.Mui-selected': {
                                        color: '#1976d2',
                                        backgroundColor: 'transparent'
                                    },
                                    '&:hover': {
                                        backgroundColor: 'transparent'
                                    },
                                    textTransform: 'none'
                                }
                            }}
                        >
                            <Tab label="Current Streak" />
                            <Tab label="Max Streak" />
                        </Tabs>
                    </Box>
                </div>
                <div className="table-container">
                    <Box sx={{ p: '12px 0' }}>
                        {tabValue === 0 && (
                            <LeaderboardTable data={currentStreakData} type="current" />
                        )}
                        {tabValue === 1 && (
                            <LeaderboardTable data={maxStreakData} type="max" />
                        )}
                    </Box>
                </div>
            </div>
            <Navbar />
        </>
    );
};

export default LeaderboardPage; 