import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveUser } from '../reducers/user';
import Header from '../components/Header';
import Wordle from '../components/Wordle';
import WordleOverlayComponent from '../components/WordleOverlayComponent';
import { backendDomain } from '../constants';

const WordlePage = ({ solution, existingGuesses, formattedGuesses, gameId, user, message }) => {
    const showOverlay = !user._id || (user._id && existingGuesses && existingGuesses.length === 0);
    //console.log("Loading Wordle Page for: ",solution, gameId, user._id);
    return (
        <>
            <Header />
            {showOverlay && <WordleOverlayComponent user={user} />}
            {gameId && (
                <Wordle
                    solution={solution}
                    existingGuesses={existingGuesses}
                    formattedGuesses={formattedGuesses}
                    gameId={gameId}
                    user={user}
                    message={message}
                />
            )}
        </>
    );
};

export default WordlePage;
