import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { LuSwords } from "react-icons/lu";
import { backendDomain } from "../constants";
import './css/NotificationCard.css';
import { fetchWithAuth } from '../utils/apiUtils';

const NotificationCard = ({ notification }) => {
    const [sourceUserData, setSourceUserData] = useState(null);
    const [timeAgo, setTimeAgo] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        calculateTimeAgo();
    }, [notification]);

    const calculateTimeAgo = () => {
        const now = new Date();
        const notificationDate = new Date(notification.createdAt);
        const diffInSeconds = Math.floor((now - notificationDate) / 1000);

        const intervals = [
            { label: 'y', seconds: 31536000 },
            { label: 'mo', seconds: 2592000 },
            { label: 'd', seconds: 86400 },
            { label: 'h', seconds: 3600 },
            { label: 'm', seconds: 60 },
            { label: 's', seconds: 1 }
        ];

        for (let i = 0; i < intervals.length; i++) {
            const interval = intervals[i];
            const count = Math.floor(diffInSeconds / interval.seconds);
            if (count >= 1) {
                setTimeAgo(`${count}${interval.label}`);
                return;
            }
        }
        setTimeAgo('0s');
    };

    const markNotificationAsRead = async () => {
        try {
            await fetchWithAuth(`${backendDomain}/notifs/${notification._id}/read`, {
                method: "PUT"
            });
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    const handleClick = () => {
        // Mark notification as read
        markNotificationAsRead();

        // Navigate based on notification type
        switch (notification.notificationType) {
            case 'comment':
                navigate(`/post/${notification.postId}#comment-${notification.commentId}`);
                break;
            case 'like':
                navigate(`/post/${notification.postId}`);
                break;
            case 'battle':
                navigate(`/post/${notification.postId}#comment-${notification.commentId}`);
                break;
            default:
                break;
        }
    };

    const handleUserClick = (e) => {
        e.stopPropagation(); // Prevent triggering the parent onClick
        if (notification.sourceUserId) {
            navigate(`/profile/${notification.sourceUserId}`);
        }
    };

    const getNotificationIcon = () => {
        switch (notification.type) {
            case 'BATTLE':
                return <LuSwords className="notification-type-icon battle" />;
            default:
                return null;
        }
    };

    const getNotificationText = () => {
        switch (notification.notificationType) {
            case 'battle':
                return 'finished challenge';
            case 'comment':
                return 'commented';
            default:
                return '';
        }
    };

    return (
        <div className={`notification-card ${!notification.read ? 'unread' : ''}`} onClick={handleClick}>
            <div className="user-avatar-container" onClick={handleUserClick}>
                {notification?.image ? (
                    <img src={notification.image} alt="user" className="user-avatar" />
                ) : (
                    <AccountCircleIcon className="user-avatar" />
                )}
            </div>
            <div className="notification-content-wrapper">
                <div className="notification-header">
                    <div className="user-info">
                        <span className="user-name" onClick={handleUserClick}>
                            {notification?.name ? notification.name : 'Anonymous'}
                        </span>
                        <span className="notification-action">{getNotificationText()}</span>
                        <span className="time">{timeAgo}</span>
                    </div>
                </div>
                <div className="notification-content">
                    <p>
                        {getNotificationIcon()}
                        {notification.content}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NotificationCard; 