import React, { useEffect, useState } from 'react';
import useWordle from '../hooks/useWordle';
import Grid from './Grid';
import Keypad from './Keypad';
import Modal from './Modal';
import ReactConfetti from 'react-confetti';
import './css/WordleOverlayComponent.css';

/**
 * Wordle component that manages the game UI and state.
 * 
 * @param {Object} props
 * @param {string} props.solution - The correct word to guess.
 * @param {Array<string>} props.existingGuesses - Previous guesses made by the user.
 * @param {string} props.gameId - Unique identifier for the game.
 * @param {Object} props.user - User information.
 * @param {string} props.message - Additional message related to the game.
 */
export default function Wordle({ solution, existingGuesses, formattedGuesses, gameId, user, message, gameMode = "dailyWordle" }) {
  const [initialGuesses, setInitialGuesses] = useState(existingGuesses);
  const [celebrating, setCelebrating] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Simplified confetti configuration
  const confettiConfig = {
    recycle: false,
    gravity: 0.5,
    numberOfPieces: 200,
    colors: ['#FFD700', '#FFA500', '#FF69B4', '#87CEEB', '#9b59b6', '#2ecc71'],
    opacity: 0.9,
    angle: 90, // Straight down
    spread: 45, // Moderate spread
  };

  // New function to merge key colors
  const mergeKeyColors = (formattedGuesses) => {
    const mergedUsedKeys = {};  

    if(!formattedGuesses)
      return mergedUsedKeys;
    // Process formattedGuesses
    formattedGuesses.forEach(guess => {
      if (guess) {
        guess.forEach(letter => {
          const key = letter.key.toLowerCase();
          const color = letter.color;
          if (color === 'green' || (color === 'yellow' && mergedUsedKeys[key] !== 'green') || (color === 'grey' && !mergedUsedKeys[key])) {
            mergedUsedKeys[key] = color;
          }
        });
      }
    });

    return mergedUsedKeys;
  };

  const mergedUsedKeys = mergeKeyColors(formattedGuesses);

  // Use the custom useWordle hook to manage game state
  const {
    currentGuess,
    guesses,
    turn,
    isCorrect,
    usedKeys,
    handleKeyup,
    resetUsedKeys,
    finalSolution,
    showModal,
    setShowModal,
    isSubmitting  // Added this from useWordle
  } = useWordle(
    solution,
    initialGuesses,
    formattedGuesses,
    gameId,
    user,
    message,
    mergedUsedKeys,
    gameMode
  );

  // Update initialGuesses when existingGuesses prop changes
  useEffect(() => {
    setInitialGuesses(existingGuesses);
  }, [existingGuesses]);

  // Handle keyboard events and rely on useWordle for modal logic
  useEffect(() => {
    window.addEventListener('keyup', handleKeyup);
    if (isCorrect || turn > 5) {
      window.removeEventListener('keyup', handleKeyup);
    }

    return () => window.removeEventListener('keyup', handleKeyup);
  }, [handleKeyup, isCorrect, turn]);

  // Add window resize handler
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Modified win celebration effect with simpler timing
  useEffect(() => {
    if (isCorrect) {
      console.log("Win detected! Waiting for animations...");
      
      // Start confetti after 1 second
      setTimeout(() => {
        console.log("Starting celebration...");
        setCelebrating(true);
        
        // Show results modal after confetti starts
        setTimeout(() => {
          setShowResults(true);
        }, 2000);
      }, 800);
    }
  }, [isCorrect]);

  const handleCloseModal = () => {
    setShowModal(false);
    // Reload the page if not in a game mode
    if (!gameId) {
      window.location.reload();
    }
  };

  return (
    <div className="wordle-container">
      {celebrating && (
        <ReactConfetti
          width={windowSize.width}
          height={windowSize.height}
          {...confettiConfig}
        />
      )}
      
      <div className="wordle-content">
        <Grid 
          guesses={guesses} 
          currentGuess={currentGuess} 
          turn={turn} 
          isSubmitting={isSubmitting}
        />
      </div>
      <Keypad 
        usedKeys={usedKeys} 
        handleKeyup={handleKeyup} 
        isCorrect={isCorrect}
        isSubmitting={isSubmitting}
      />
      {showResults && showModal && (
        <Modal
          isCorrect={isCorrect}
          turn={turn}
          solution={finalSolution.toUpperCase()}
          onClose={handleCloseModal}
          id={gameId}
          message={message}
        />
      )}
    </div>
  );
}
