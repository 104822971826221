import React, { useState, useEffect } from 'react';
import './css/BattleTutorial.css';

const BattleTutorial = ({ onClose }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [overlayDimensions, setOverlayDimensions] = useState(null);

  useEffect(() => {
    // Add a small delay to ensure grid is rendered
    const timer = setTimeout(() => {
      updateOverlayDimensions();
    }, 100);

    // Update dimensions when window is resized
    window.addEventListener('resize', updateOverlayDimensions);
    
    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', updateOverlayDimensions);
    };
  }, [currentStep]);

  const updateOverlayDimensions = () => {
    // Check if grid rows are properly rendered
    const dailyRowElement = document.querySelector('[data-tutorial-row="daily-wordle"]');
    const opponentRowElement = document.querySelector('[data-tutorial-row="opponent-guess"]');
    
    if (!dailyRowElement?.offsetHeight || !opponentRowElement?.offsetHeight) {
      // If rows aren't properly rendered yet, try again after a short delay
      console.log('Rows not ready, retrying...');
      setTimeout(updateOverlayDimensions, 50);
      return;
    }

    console.log('Header Height:', document.querySelector('header')?.offsetHeight);
    console.log('Grid Container:', document.querySelector('.grid-wordle'));
    console.log('Daily Row:', dailyRowElement);
    console.log('Daily Row Position:', dailyRowElement?.getBoundingClientRect());
    
    const getRowPosition = (selector) => {
      const element = document.querySelector(`[data-tutorial-row="${selector}"]`);
      if (!element) {
        console.log(`Element not found for selector: ${selector}`);
        return null;
      }
      
      const rect = element.getBoundingClientRect();
      console.log(`Row position for ${selector}:`, {
        top: rect.top,
        height: rect.height,
        bottom: rect.bottom
      });
      
      return {
        top: rect.top,
        height: rect.height,
        bottom: rect.bottom
      };
    };

    const getMultipleRowsPosition = (selector) => {
      const elements = document.querySelectorAll(`[data-tutorial-row="${selector}"]`);
      if (!elements.length) return null;

      const firstRect = elements[0].getBoundingClientRect();
      const lastRect = elements[elements.length - 1].getBoundingClientRect();
      
      return {
        top: firstRect.top,
        height: lastRect.bottom - firstRect.top,
        bottom: lastRect.bottom
      };
    };

    // Get the second row position first
    const opponentRow = getRowPosition('opponent-guess');
    console.log('Opponent Row Position:', opponentRow);

    switch(currentStep) {
      case 1:
        if (opponentRow) {
          const rowHeight = opponentRow.height;
          const headerHeight = document.querySelector('header')?.offsetHeight || 0;
          
          // Calculate first row position based on second row
          const firstRowPosition = {
            top: opponentRow.top - rowHeight,
            height: rowHeight,
            bottom: opponentRow.top
          };
          
          console.log('Calculated First Row Position:', firstRowPosition);
          
          setOverlayDimensions({
            topSection: {
              top: firstRowPosition.top,
              height: firstRowPosition.height,
              bottom: firstRowPosition.bottom
            },
            messageTop: firstRowPosition.bottom + 20
          });
        }
        break;
      case 2:
        const opponentSection = getRowPosition('opponent-guess');
        setOverlayDimensions({
          topSection: opponentSection,
          messageTop: opponentSection?.bottom + 20
        });
        break;
      case 3:
        const guessesSection = getMultipleRowsPosition('player-guess');
        setOverlayDimensions({
          topSection: guessesSection,
          messageTop: guessesSection?.bottom + 20
        });
        break;
      default:
        setOverlayDimensions(null);
    }
  };

  const renderOverlaySections = () => {
    if (!overlayDimensions?.topSection) return null;

    const { topSection } = overlayDimensions;
    const headerHeight = document.querySelector('header')?.offsetHeight || 0;
    
    return (
      <>
        {/* Dark overlay for top section */}
        <div className="overlay-section dark" 
             style={{ 
               position: 'fixed',
               top: headerHeight,
               height: Math.max(0, topSection.top - headerHeight)
             }} />
        
        {/* Transparent section for highlighted row */}
        <div className="overlay-section" 
             style={{ 
               position: 'fixed',
               top: topSection.top,
               height: topSection.height,
               background: 'transparent'
             }} />
        
        {/* Dark overlay for bottom section */}
        <div className="overlay-section dark" 
             style={{ 
               position: 'fixed',
               top: topSection.bottom,
               bottom: 0
             }} />
      </>
    );
  };

  const steps = [
    {
      id: 1,
      text: "The first word is the Daily Wordle solution",
      position: 'top'
    },
    {
      id: 2,
      text: "The second word is your opponent's first guess",
      position: 'middle'
    },
    {
      id: 3,
      text: "Solve your opponent's guess in 4 tries or less to win!",
      position: 'bottom'
    }
  ];

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    } else {
      onClose();
    }
  };

  return (
    <div className="tutorial-overlay">
      {renderOverlaySections()}
      <div className="tutorial-content">
        {steps.map((step) => (
          <div
            key={step.id}
            className={`tutorial-step ${
              currentStep === step.id ? 'active' : ''
            }`}
            style={{
              top: currentStep === step.id && overlayDimensions?.messageTop 
                ? `${overlayDimensions.messageTop}px` 
                : '0px',
              opacity: currentStep === step.id ? 1 : 0
            }}
          >
            {currentStep === step.id && (
              <>
                <p>{step.text}</p>
                <button 
                  onClick={handleNext}
                  className="tutorial-button"
                >
                  {currentStep === steps.length ? "Got it!" : "Next"}
                </button>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BattleTutorial; 