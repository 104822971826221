import { backendDomain } from '../constants';

export const requestNotificationPermission = async (emailId) => {
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    console.log('Notification permission granted.');
    // Proceed to subscribe the user
    await subscribeUser(emailId);
  } else {
    console.log('Notification permission denied.');
  }
  return permission;
};

export const subscribeUser = async (emailId) => {
    console.log("Preparing to subscribe user to push notifications: ", process.env.NODE_ENV.REACT_APP_VAPID_PUBLIC_KEY);
  const registration = await navigator.serviceWorker.ready;
  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array("BPx9qgiNF7vryqO-zzDFxhUdVQEy8zHW_X1Z-LVQ3TqjUGRXGoYJh_J3s93JGUVPPAFmbZ0dgRqhp085cFQYBE8")
  });

  // Send subscription to the server
  //attach the userId to the subscription
  const body = {
    emailId: emailId,
    subscription: subscription
  };

  console.log("Sending subscription to server:", subscription);
  await fetch(`${backendDomain}/pn/subscribe`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
};

// Utility function to convert VAPID key
function urlBase64ToUint8Array(base64String) {
  if (!base64String) {
    throw new Error('base64String is required');
  }
  
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  
  const rawData = window.atob(base64);
  return Uint8Array.from([...rawData].map(char => char.charCodeAt(0)));
} 