import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackAppBar = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        // Check if we can go back in history
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            // If we can't go back, redirect to home
            navigate('/');
        }
    };

    return (
        <AppBar position="sticky" color="default">
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleBackClick} aria-label="back">
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    Back
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

export default BackAppBar;