import React, { useState, useEffect } from 'react'
import { backendDomain } from '../constants';
import { LuSwords } from "react-icons/lu";
import './css/UserOverview.css';
import { useNavigate } from 'react-router-dom';
import { fetchWithAuth } from '../utils/apiUtils';

/** Modal.css is breaking the entire popup. Need to fix it. 
 * import './css/Modal.css';
 */

/**
 * BattleResult Component
 * 
 * This component displays a result dialog for a battle game. It shows different messages 
 * based on whether the user has won or lost the battle. The user can submit a message 
 * related to their battle experience, which is sent to the backend.
 * 
 * Props:
 * - isCorrect: Boolean indicating if the user has won the battle.
 * - solution: String containing the correct word for the battle.
 * - turn: Number of guesses taken by the user.
 * - onClose: Function to close the modal.
 * - id: Unique identifier for the game session.
 * - message: String containing any existing message from the user.
 * - challengerName: String containing the name of the challenger.
 * - defenderName: String containing the name of the defender.
 * - challengerScore: Number containing the score of the challenger.
 * - defenderScore: Number containing the score of the defender.
 * - postId: Unique identifier for the post.
 */
export default function BattleResult({ isCorrect, solution, turn, onClose, id, message, challengerName, defenderName, challengerScore, defenderScore, postId, wordleId, commentId }) {
  const navigate = useNavigate();
  const [text, setText] = useState("")
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const now = new Date();
    const nextMidnight = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1, 0, 0, 0));
    const timeDifference = nextMidnight - now; // Time until next midnight in milliseconds
    return Math.floor(timeDifference / 1000); // Convert to seconds
  }

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timeLeft]);

  //Change this to only update the message and only for authenticated user
  const handleSubmit = async () => {
    try {
      await fetchWithAuth(`${backendDomain}/wordle/update-wordles-game-for-existing-user`, {
        method: 'POST',
        body: JSON.stringify({ id, wordle: { message: text } }),
      });
      onClose();
    } catch (error) {
      console.error('Error updating wordle:', error);
      // Could add error handling UI here
    }
  }

  const handleCloseResult = () => {
    onClose();
    console.log("Closing BattleResult for wordleId", wordleId, "and commentId", commentId);
    if (wordleId && commentId) {
      navigate(`/post/${wordleId}#comment-${commentId}`, { replace: true });
    }
    else if (wordleId) {
      navigate(`/post/${wordleId}`, { replace: true });
    }
  }

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours}h ${minutes}m ${secs}s`;
  };

  return (
    <div className="modal">
      {isCorrect && (
        <div style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <h1 style={{ marginBottom: '0px', padding: '10px' }}>👏 Well Done! You Won 🏆</h1>
          <p className="solution" style={{ color: 'green', fontSize: '24px', fontWeight: 'bold', margin: '10px' }}>🎉 {solution} 🎉</p>
          <p>You solved it in {turn} guesses</p>
          {message === "" && id && (
            <div>
              <textarea 
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Enter a message for your feed...."
                style={{
                  width: '100%',  // Adjust width to 100% of the parent container
                  height: '100px', // Set the height to 100px
                  padding: '10px', // Add padding inside the text area
                  fontSize: '16px', // Set the font size
                  borderRadius: '5px', // Add rounded corners
                  border: '1px solid #ccc', // Border styling
                  boxSizing: 'border-box', // Ensure padding and border are included in the element's width and height
                  fontFamily: 'Helvetica Neue'
                }}
              />
              <br />
              <button className="modal-button" style={{ marginTop: '5px' }} onClick={handleSubmit}>Post on Feed</button>
            </div>
          )}
          {id && (
            <>
              <div className="aggregate-battle-score">
                <LuSwords className="swords-icon" />
                <span className="user-name">{challengerName}</span>
                <strong className="score">{challengerScore+1}</strong>
                <span className="separator"> - </span>
                <strong className="score">{defenderScore}</strong>
                <span className="user-name">{defenderName}</span>
                <LuSwords className="swords-icon" />
              </div>
            </>
          )}
          <button className="modal-button" onClick={handleCloseResult}>Close</button>
        </div>
      )}
      {!isCorrect && (
        <div style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <h1 style={{ marginBottom: '0px', padding: '10px' }}>Oh No! The word was:</h1>
          <p className="solution" style={{ color: 'red', fontSize: '24px', fontWeight: 'bold', margin: '10px' }}>🥺 {solution} 😭</p>
          <p>Better luck tomorrow ♥️</p><br />
          {message === "" && id && (
            <div>
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Enter a message for your feed...."
                style={{
                  width: '100%',  // Adjust width to 100% of the parent container
                  height: '100px', // Set the height to 100px
                  padding: '10px', // Add padding inside the text area
                  fontSize: '14px', // Set the font size to match isCorrect view
                  borderRadius: '5px', // Add rounded corners
                  border: '1px solid #ccc', // Border styling
                  boxSizing: 'border-box', // Ensure padding and border are included in the element's width and height
                  fontFamily: 'Helvetica Neue'
                }}
              />
              <br />
              <button className="modal-button" style={{ marginTop: '5px' }} onClick={handleSubmit}>Post on Feed</button>
            </div>
          )}
          {id && (
            <>
                <div className="aggregate-battle-score">
                <LuSwords className="swords-icon" />
                <span className="user-name">{challengerName}</span>
                <strong className="score">{challengerScore}</strong>
                <span className="separator"> - </span>
                <strong className="score">{defenderScore+1}</strong>
                <span className="user-name">{defenderName}</span>
                <LuSwords className="swords-icon" />
                </div>
            </>
            )}
          <button className="modal-button" onClick={handleCloseResult}>Close</button>
        </div>
      )}
    </div>
  )
}
