import React from 'react'
import './css/Grid.css'
// components
import Row from './Row'

// Grid component to display guesses in a Wordle game
export default function Grid({ guesses, currentGuess, turn, isSubmitting }) {
  return (
    <div className={`grid-wordle ${isSubmitting ? 'submitting' : ''}`}>
      {guesses.map((g, i) => {
        // Check if the current index matches the turn
        if (turn === i) {
          // Render the current guess
          return <Row key={i} currentGuess={currentGuess} isSubmitting={isSubmitting} />
        }
        // Render the previous guess
        return <Row key={i} guess={g} />
      })}
    </div>
  )
}
