import React, { useState, useEffect } from 'react';
import { backendDomain } from '../constants';
import FavoriteIcon from '@mui/icons-material/Favorite';
import './css/UserOverview.css';
import { useSelector } from 'react-redux'; // Add this import
import { LuSwords } from "react-icons/lu";

const UserOverview = ({ user }) => {
    const { firstName, lastName, imageUri, _id: userId } = user; 
    const currentUser = useSelector((state) => state.user); // Use Redux selector to get current user

    const [stats, setStats] = useState({
        played: 0,
        winPercentage: 0,
        currentStreak: 0,
        maxStreak: 0,
        visitorScore: 0,
        ownerScore: 0,
    });

    useEffect(() => {
        const fetchStats = async () => {
            try {
                // Fetch game completion stats
                const response1 = await fetch(`${backendDomain}/wordle/get-wordle-completion-stats-for-user`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId }),
                });
                const data1 = await response1.json();
                if (data1.status === 200) {
                    // Fetch user streak information
                    const response2 = await fetch(`${backendDomain}/wordle/get-user-streak-information`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userId }),
                    });
                    const data2 = await response2.json();

                    if (data2.status === 200) {
                        setStats(prevStats => ({
                            ...prevStats,
                            played: data1.played,
                            winPercentage: data1.winPercentage,
                            currentStreak: data2.currentStreak,
                            maxStreak: data2.maxStreak,
                        }));
                    } else {
                        console.error('Failed to fetch streak information');
                    }
                } else {
                    console.error('Failed to fetch completion stats');
                }

                
                // Fetch aggregate battle score only if currentUser is available
                if (currentUser && currentUser._id && currentUser._id !== user._id) {
                    const response3 = await fetch(`${backendDomain}/wordle/battle/head-to-head-score`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ challengerId: currentUser._id, defenderId: user._id }),
                    });
                    const data3 = await response3.json();
                    if (data3.status === 200) {
                        setStats(prevStats => ({
                            ...prevStats,
                            visitorScore: data3.challengerScore,
                            ownerScore: data3.defenderScore,
                        }));
                    } else {
                        console.error('Failed to fetch aggregate battle score');
                    }
                }
            } catch (error) {
                console.error('Error fetching stats:', error);
            }
        };

        fetchStats();
    }, [userId, imageUri, currentUser._id]);

    return (
        <div className="container">
            <img src={imageUri} alt={`${firstName} ${lastName}`} className="image" />
            <div className="name">{`${firstName} ${lastName}`}</div>
            
            {/* Add the new aggregate battle score element */}
            {currentUser && currentUser._id && currentUser._id !== user._id && ( // Check if currentUser is defined and not viewing their own profile
                <div className="aggregate-battle-score">
                    <LuSwords className="swords-icon" />
                    <span className="user-name">{currentUser.firstName}</span>
                    <strong className="score">{stats.visitorScore}</strong>
                    <span className="separator"> - </span>
                    <strong className="score">{stats.ownerScore}</strong>
                    <span className="user-name">{firstName}</span>
                    <LuSwords className="swords-icon" />
                </div>
            )}
            
            <div className="additional-stats">
                <div className="stat">
                    <div className="stat-number">{stats.played}<br />
                        <span className="stat-subtext">Games</span>
                    </div>
                    <div className="stat-text">Played</div>
                </div>
                <div className="stat">
                    <div className="stat-number">{Math.ceil(stats.winPercentage)}%<br /> {/* Rounded up */}
                    <span className="stat-subtext">Win</span>
                    </div>
                    <div className="stat-text">Ratio</div>
                </div>
                <div className="stat">
                    <div className="stat-number">
                        {stats.currentStreak > 0 ? `${stats.currentStreak}🔥` : stats.currentStreak}<br />
                        <span className="stat-subtext">Current</span>
                    </div>
                    <div className="stat-text">Streak</div>
                </div>
                <div className="stat">
                    <div className="stat-number">
                        {stats.maxStreak}<br />
                        <span className="stat-subtext">Max</span>
                    </div>
                    <div className="stat-text">Streak</div>
                </div>
            </div>
        </div>
    );
};

export default UserOverview;
