import React, { useState, useEffect } from 'react';
import useWordle from '../hooks/useWordle';
import GridBattle from './GridBattle';
import Keypad from './Keypad';
import BattleResult from './BattleResult';
import BattleTutorial from './BattleTutorial';
import { tutorialService } from '../utils/tutorialService';

/**
 * Battle component that manages the battle game UI and state.
 * 
 * @param {Object} props
 * @param {string} props.solution - The correct word to guess.
 * @param {Array<string>} props.existingGuesses - Previous guesses made by the user.
 * @param {Array<Array<Object>>} props.formattedGuesses - Formatted guesses.
 * @param {string} props.gameId - Unique identifier for the game.
 * @param {Object} props.user - User information.
 * @param {string} props.message - Additional message related to the game.
 * @param {Function} props.onGuessSubmitted - Callback ffunction when a guess is submitted.
 * @param {string} props.battleDayWordleSolution - The day's Wordle solution for battles.
 * @param {Object} props.defender - Defender information.
 * @param {Object} props.challenger - Challenger information.
 * @param {number} props.challengerScore - Challenger score.
 * @param {number} props.defenderScore - Defender score.
 * @param {boolean} props.showTutorial - Whether to show the tutorial.
 * @param {Function} props.onCloseTutorial - Callback function to close the tutorial.
 */
export default function Battle({ 
    solution, 
    existingGuesses, 
    formattedGuesses, 
    formattedSolution, 
    gameId, 
    user, 
    message, 
    onGuessSubmitted, 
    battleDayWordleSolution,
    defender,           // Add this
    challenger,        // Add this
    challengerScore,   // Add this
    defenderScore,     // Add this
    defenderName,
    wordleId,
    commentId,
    showTutorial,
    onCloseTutorial,
}) {
  const [initialGuesses, setInitialGuesses] = useState(existingGuesses);
  const [resultShownOnce, setResultShownOnce] = useState(false);

  if (battleDayWordleSolution === undefined) {
    battleDayWordleSolution = "     ";
  }

  // New function to generate initial used keys
  const generateInitialUsedKeys = (battleDayWordleSolution, formattedSolution) => {
    const initialUsedKeys = {};

    // Check if formattedSolution is defined and has any yellow letters
    const hasYellow = formattedSolution && formattedSolution.some(letter => letter.color === 'yellow');
    const hasGreen = formattedSolution && formattedSolution.some(letter => letter.color === 'green');
    const yellowCount = formattedSolution ? formattedSolution.filter(letter => letter.color === 'yellow').length : 0;

    if (formattedSolution && !hasYellow) {
      // If there are no yellows, set colors based on formattedSolution
      battleDayWordleSolution.split('').forEach((letter, index) => {
        if (formattedSolution[index]) {
          const letterLowerCase = letter.toLowerCase();
          const letterColor = initialUsedKeys[letterLowerCase];
          const newLetterColor = formattedSolution[index].color;

          //Logic to handle repeating letter coloring  
          if(!letterColor || letterColor === 'grey' || (letterColor === 'yellow' && newLetterColor === 'green')){
            initialUsedKeys[letter.toLowerCase()] = newLetterColor;
          };
        }
      });
    }
    else if (formattedSolution && yellowCount === 1) {
      // New condition: If there's only one yellow, mark the corresponding letter as grey if it appears only once
      const letterCounts = {};
      battleDayWordleSolution.split('').forEach(letter => {
        letterCounts[letter] = (letterCounts[letter] || 0) + 1;
      });

      battleDayWordleSolution.split('').forEach((letter, index) => {
        const letterLowerCase = letter.toLowerCase();
        if (formattedSolution[index].color === 'yellow'){
          //If only one occurrence of letter, then mark it as grey with confidence
          if (letterCounts[letter] === 1) {
            initialUsedKeys[letterLowerCase] = 'grey';
          } else { 
            //More than one occurrence means we need to make sure other occurrences are grey before marking letter as grey
            //Loop through the list again and check color
            let otherOccurrencesAreGrey = true;
            battleDayWordleSolution.split('').forEach((letter2, index2) => {
              if(index !== index2 && letter === letter2 && formattedSolution[index2].color !== 'grey'){
                  otherOccurrencesAreGrey = false;
              }
            });    

            //Now if condition is met, then set to grey
            if (otherOccurrencesAreGrey) {
              initialUsedKeys[letterLowerCase] = 'grey';
            }
          }
        } 
        else if (formattedSolution[index].color === 'green') {
          initialUsedKeys[letterLowerCase] = formattedSolution[index].color;
        }
      });
    }
    else if (formattedSolution && hasGreen) {
      // If there are greens, just set the greens
      battleDayWordleSolution.split('').forEach((letter, index) => {
        if (formattedSolution[index] && formattedSolution[index].color === 'green') {
          initialUsedKeys[letter.toLowerCase()] = 'green';
        }
      });
    }
    return initialUsedKeys;
  };

  // New function to merge key colors
  const mergeKeyColors = (formattedGuesses, initialUsedKeys) => {
    const mergedUsedKeys = { ...initialUsedKeys };

    // Process formattedGuesses
    formattedGuesses.forEach(guess => {
      if (guess) {
        guess.forEach(letter => {
          const key = letter.key.toLowerCase();
          const color = letter.color;
          if (color === 'green' || (color === 'yellow' && mergedUsedKeys[key] !== 'green') || (color === 'grey' && !mergedUsedKeys[key])) {
            mergedUsedKeys[key] = color;
          }
        });
      }
    });

    return mergedUsedKeys;
  };

  // Generate initial used keys
  const initialUsedKeys = generateInitialUsedKeys(battleDayWordleSolution, formattedSolution);

  // Merge initial used keys with colors from formatted guesses
  const mergedUsedKeys = mergeKeyColors(formattedGuesses, initialUsedKeys);

  // Use the custom useWordle hook to manage game state
  const { 
    currentGuess, 
    guesses, 
    turn, 
    isCorrect, 
    usedKeys, 
    handleKeyup, 
    resetUsedKeys, 
    finalSolution,
    showModal,
    setShowModal
  } = useWordle(
    solution,
    initialGuesses,
    formattedGuesses,
    gameId,
    user,
    message,
    mergedUsedKeys,  // Pass the merged used keys here
    "battle",
    battleDayWordleSolution
  );
  
  const maxTurns = 4;

  // Update initialGuesses when existingGuesses prop changes
  useEffect(() => {
    setInitialGuesses(existingGuesses);
  }, [existingGuesses]);

  // Handle keyboard events and result display with a 1-second delay
  useEffect(() => {
    window.addEventListener('keyup', handleKeyup);

    if (isCorrect || turn > maxTurns - 1) {
      window.removeEventListener('keyup', handleKeyup);
    }

    if ((isCorrect || turn > maxTurns - 1) && !showModal && !resultShownOnce) {
      const timer = setTimeout(() => {
        setShowModal(true);
        setResultShownOnce(true); // Ensure result is only shown once
      }, 1500); // 1-second delay

      return () => clearTimeout(timer); // Cleanup the timer if dependencies change
    }

    return () => window.removeEventListener('keyup', handleKeyup);
  }, [handleKeyup, isCorrect, turn, showModal, setShowModal, resultShownOnce]);

  const handleCloseResult = () => {
    setShowModal(false);
    // Do not reset resultShownOnce to prevent re-opening the result
    if (!gameId)
      window.location.reload();
  };

  return (
    <div className="wordle-container">
      <div className="wordle-content">
        <GridBattle 
          guesses={guesses} 
          currentGuess={currentGuess} 
          turn={turn} 
          solution={finalSolution} 
          formattedSolution={formattedSolution}
          battleDayWordleSolution={battleDayWordleSolution}
        />
      </div>
      <Keypad usedKeys={usedKeys} handleKeyup={handleKeyup} isCorrect={isCorrect} />
      {showModal && (
        <BattleResult 
          isCorrect={isCorrect} 
          turn={turn} 
          solution={finalSolution.toUpperCase()} 
          onClose={handleCloseResult} 
          id={gameId} 
          message={message}
          challengerName={user.firstName}
          defenderName={defenderName}
          challengerScore={challengerScore}
          defenderScore={defenderScore}
          wordleId={wordleId}
          commentId={commentId}
        />
      )}
      {showTutorial && <BattleTutorial onClose={onCloseTutorial} />}
    </div>
  );
}
