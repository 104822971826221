import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import './css/StaticNavHeader.css';
import { fetchWithAuth } from '../utils/apiUtils';
import { backendDomain } from '../constants';

const StaticNavHeader = ({ onTutorialClick, showHelpButton }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state) => state.user);
    const [notificationCount, setNotificationCount] = React.useState(0);

    React.useEffect(() => {
        if (user && user._id) {
            fetchNotificationCount();
        }
    }, [user]);

    const fetchNotificationCount = async () => {
        try {
            const response = await fetchWithAuth(`${backendDomain}/notifs/unread-count`, {
                method: 'GET'
            });
            const data = await response.json();
            if (data.status === 200) {
                setNotificationCount(data.count);
            }
        } catch (error) {
            console.error('Error fetching notification count:', error);
        }
    };

    const handleNotificationClick = () => {
        navigate('/notifications');
    };

    const handleProfileClick = () => {
        navigate('/profile');
    };

    return (
        <div className="static-nav-header">
            <div className="header-content">
                <div className="header-left">
                    <img src='/assets/social-wordle.png' alt="Social Wordle Header" className="header-image" />
                </div>
                <div className="header-right">
                    {showHelpButton && (
                        <div className="nav-icon-container" onClick={onTutorialClick}>
                            <HelpOutlineIcon className="nav-icon" />
                        </div>
                    )}
                    <div className="nav-icon-container" onClick={handleNotificationClick}>
                        <Badge 
                            badgeContent={notificationCount} 
                            color="error"
                            className="notification-badge"
                        >
                            <NotificationsIcon className={`nav-icon ${location.pathname === '/notifications' ? 'active' : ''}`} />
                        </Badge>
                    </div>
                    <div className="nav-icon-container" onClick={handleProfileClick}>
                        {user.imageUri ? (
                            <img src={user.imageUri} alt="Profile" className="profile-icon" />
                        ) : (
                            <AccountCircleIcon className="profile-icon" />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaticNavHeader;
