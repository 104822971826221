import React, { useEffect, useState } from 'react';
import { googleLogout } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { saveUser, clearUser } from '../reducers/user';
import { backendDomain } from '../constants';
import './css/ProfilePage.css';
import '../components/css/WordleOverlayComponent.css';
import Header from '../components/Header';
import UserOverview from '../components/UserOverview';
import GuessDistributionOverview from '../components/GuessDistributionOverview';
import FeedComponent from '../components/FeedComponent';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { requestNotificationPermission } from '../utils/notificationUtils';
import { clearAuth } from '../utils/auth';

const ProfilePage = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    console.log("User details in profile page at initialisation", user);
    const [notificationPermission, setNotificationPermission] = useState(() => {
        if (typeof window !== 'undefined' && 'Notification' in window) {
            return Notification.permission;
        }
        return 'default';
    });
    const [loginError, setLoginError] = useState(null);

    useEffect(() => {
        // Check for error parameter in URL
        const params = new URLSearchParams(window.location.search);
        const error = params.get('error');
        if (error) {
            setLoginError('Authentication failed. Please try again.');
            // Clean up error from URL
            window.history.replaceState({}, document.title, window.location.pathname);
            return;
        }

        // Check for stored credentials
        const token = localStorage.getItem('token');
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (token && storedUser) {
            console.log("Stored user in profile page", storedUser);
            dispatch(saveUser(storedUser));
        }
    }, [dispatch]);

    const handleGoogleLogin = () => {
        setLoginError(null);
        // Redirect to backend's Google auth endpoint
        window.location.href = `${backendDomain}/auth/google`;
    };

    const onLogout = () => {
        googleLogout();
        clearAuth();
    };

    const handleNotificationClick = async () => {
        if (!('Notification' in window)) {
            alert('This browser does not support desktop notifications');
            return;
        }

        if (user.email) {
            const permission = await requestNotificationPermission(user.email);
            setNotificationPermission(permission);
        }
    };
    console.log("User details in profile page", user);
    return (
        <>
            <Header />
            <div className={`profile-page ${user.email ? 'profile-page-logged-in' : 'profile-page-logged-out'}`}>
                {loginError && (
                    <div className="login-error">
                        {loginError}
                    </div>
                )}
                {user.email ? (
                    <div className="profile-content">
                        <UserOverview user={user} />
                        <GuessDistributionOverview user={user} />
                        <FeedComponent user={user} isGlobal={false} isProfilePage={true} />
                    </div>
                ) : (
                    <>
                        <div className="container">
                            <div className="icon">
                                <div className="grid">
                                    {[...Array(9)].map((_, i) => (
                                        <div key={i} className={`grid-cell ${i >= 3 && i <= 5 ? 'green-cell' : ''}`}></div>
                                    ))}
                                </div>
                            </div>
                            <h1 className="title">Wordle Stats</h1>
                            <p className="description">Login and start building your wordle profile</p>
                        </div>
                        <div className="profile-content">
                            <h3>Login to continue</h3>
                            <div className="google-login-container">
                                <button 
                                    className="google-login-button"
                                    onClick={handleGoogleLogin}
                                >
                                    Sign in with Google
                                </button>
                            </div>
                        </div>
                    </>
                )}
                {user.email && (
                    <>
                        <div className="logout-icon-container" onClick={onLogout}>
                            <LogoutIcon style={{ color: 'grey', marginBottom: '-10px' }} />
                            <p style={{ fontSize: '12px', color: 'grey' }}>Logout</p>
                        </div>
                        <div className="notification-icon-container" onClick={handleNotificationClick}>
                            {notificationPermission === 'granted' ? (
                                <NotificationsActiveIcon style={{ color: '#4CAF50', marginBottom: '-10px' }} />
                            ) : (
                                <NotificationsIcon style={{ color: 'grey', marginBottom: '-10px' }} />
                            )}
                            <p style={{ fontSize: '12px', color: 'grey' }}>Alerts</p>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default ProfilePage;
