import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { backendDomain } from "../constants";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; // Import default avatar icon
import './css/Comment.css';
import { battleService } from '../hooks/battleService';
import { LuSwords } from "react-icons/lu";

const Comment = ({ comment }) => {
    const loggedInUser = useSelector((state) => state.user);
    const [liked, setLiked] = useState(false);
    const [likesCount, setLikesCount] = useState(comment.likesCount || 0);
    const [timeAgo, setTimeAgo] = useState('');
    const [commenterData, setCommenterData] = useState(null);
    const [battleData, setBattleData] = useState(null);

    useEffect(() => {
        setLiked(comment.likers && comment.likers.includes(loggedInUser.email));
        calculateTimeAgo();
        fetchCommenterData();
        if (comment.battleId) {
            fetchBattleData(comment.battleId);
        }
    }, [comment, loggedInUser]);

    const fetchCommenterData = async () => {
        try {
            const response = await fetch(`${backendDomain}/user/fetch-user`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ id: comment.userId }),
            });
            const userData = await response.json();
            if (userData.status === 200) {
                setCommenterData(userData.user);
            } else {
                console.error('Failed to fetch commenter data:', userData.message || 'Unknown error');
            }
        } catch (error) {
            console.error('Error fetching commenter data:', error);
        }
    };

    const fetchBattleData = async (battleId) => {
        try {
            const battleState = await battleService.getBattleResult(battleId);
            setBattleData(battleState);
        } catch (error) {
            console.error('Error fetching battle data:', error);
        }
    };

    const generateGrid = () => {
        if (!battleData || !battleData.grid) return [];
        return battleData.grid;
    };

    const handleLikeClick = async () => {
        if (!loggedInUser.email) return;

        setLiked(!liked);
        const newLikesCount = liked ? likesCount - 1 : likesCount + 1;
        setLikesCount(newLikesCount);

        try {
            const response = await fetch(`${backendDomain}/wordle/comments/${comment._id}/like`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: loggedInUser._id }),
            });
            const data = await response.json();
            if (data.status !== 200) {
                console.error('Failed to update like');
                setLiked(!liked);
                setLikesCount(likesCount);
            }
        } catch (error) {
            console.error('Error updating like:', error);
            setLiked(!liked);
            setLikesCount(likesCount);
        }
    };

    const calculateTimeAgo = () => {
        const now = new Date();
        const commentDate = new Date(comment.createdAt);
        const diffInSeconds = Math.floor((now - commentDate) / 1000);

        const intervals = [
            { label: 'y', seconds: 31536000 },
            { label: 'mo', seconds: 2592000 },
            { label: 'd', seconds: 86400 },
            { label: 'h', seconds: 3600 },
            { label: 'm', seconds: 60 },
            { label: 's', seconds: 1 }
        ];

        for (let i = 0; i < intervals.length; i++) {
            const interval = intervals[i];
            const count = Math.floor(diffInSeconds / interval.seconds);
            if (count >= 1) {
                setTimeAgo(`${count}${interval.label}`);
                return;
            }
        }

        setTimeAgo('0s');
    };

    const grid = generateGrid();

    return (
        <div className="comment-component" id={`comment-${comment._id}`}>
            <div className="user-avatar-container">
                {commenterData && commenterData.imageUri ? (
                    <img src={commenterData.imageUri} alt="user" className="user-avatar" />
                ) : (
                    <AccountCircleIcon className="user-avatar" />
                )}
            </div>
            <div className="comment-content-wrapper">
                <div className="comment-header">
                    <div className="user-info">
                        <span className="user-name">
                            {commenterData ? `${commenterData.firstName} ${commenterData.lastName}` : 'Anonymous'}
                        </span>
                        <span className="time">{timeAgo}</span>
                    </div>
                </div>
                {comment.battleId && battleData ? (
                    <>
                        <div className="comment-content">
                            <p><LuSwords /> {comment.content}</p>
                        </div>
                        <div className="spacing"></div>
                        <div className="wordle-grid">
                            {grid.map((row, rowIndex) => (
                                <div key={rowIndex} className="wordle-row">
                                    {row.map((color, colIndex) => (
                                        <div key={colIndex} className={`wordle-cell ${color}`}></div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <div className="comment-content">
                        <p>{comment.content}</p>
                    </div>
                )}
                <div className="comment-footer">
                    {/* Commenting out like functionality
                    {liked ? (
                        <FavoriteIcon
                            className="heart-icon liked"
                            onClick={handleLikeClick}
                            style={{ cursor: loggedInUser.email ? 'pointer' : 'not-allowed' }}
                        />
                    ) : (
                        <FavoriteBorderIcon
                            className="heart-icon"
                            onClick={handleLikeClick}
                            style={{ cursor: loggedInUser.email ? 'pointer' : 'not-allowed' }}
                        />
                    )}
                    <span className="likes-count">{likesCount}</span>
                    */}
                </div>
            </div>
        </div>
    );
};

export default Comment;