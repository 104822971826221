const getSecurityInfo = async () => {
    try {
        // Get browser and OS info
        const userAgent = navigator.userAgent;
        const platform = navigator.platform;
        
        // Get screen info
        const screenInfo = {
            width: window.screen.width,
            height: window.screen.height,
            colorDepth: window.screen.colorDepth
        };

        // Get timezone
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        
        // Get language
        const language = navigator.language;

        // Get rough location (if available)
        let location = null;
        try {
            const response = await fetch('https://ipapi.co/json/');
            location = await response.json();
        } catch (error) {
            console.error('Failed to get location:', error);
        }

        return {
            userAgent,
            platform,
            screenInfo,
            timezone,
            language,
            location,
            timestamp: new Date().toISOString()
        };
    } catch (error) {
        console.error('Error gathering security info:', error);
        return {
            timestamp: new Date().toISOString(),
            error: 'Failed to gather complete security info'
        };
    }
};

export default getSecurityInfo; 