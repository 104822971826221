import React from 'react'
import Navbar from './Navbar'
import StaticNavHeader from './StaticNavHeader'

const Header = ({ showHelpButton, onTutorialClick }) => {
    return (
        <>
            <StaticNavHeader 
                showHelpButton={showHelpButton} 
                onTutorialClick={onTutorialClick}
            />
            <Navbar />
        </>
    )
}

export default Header