const TUTORIAL_KEY = 'battle_tutorial_shown';

export const tutorialService = {
  hasTutorialBeenShown: () => {
    return localStorage.getItem(TUTORIAL_KEY) === 'true';
  },

  setTutorialShown: () => {
    localStorage.setItem(TUTORIAL_KEY, 'true');
  },

  resetTutorial: () => {
    localStorage.removeItem(TUTORIAL_KEY);
  }
}; 