import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import NotificationCard from '../components/NotificationCard';
import Navbar from '../components/Navbar';
import StaticNavHeader from '../components/StaticNavHeader';
import { backendDomain } from '../constants';
import { fetchWithAuth } from '../utils/apiUtils';
import './css/NotificationsPage.css';

const NotificationsPage = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const user = useSelector((state) => state.user);

    useEffect(() => {
        if (user && user._id) {
            fetchNotifications();
        }
    }, [user]);

    const fetchNotifications = async () => {
        try {
            const response = await fetchWithAuth(`${backendDomain}/notifs`, {
                method: 'GET'
            });
            const data = await response.json();
            
            if (data.status === 200) {
                setNotifications(data.notifications);
            } else {
                setError('Failed to fetch notifications');
            }
        } catch (error) {
            setError('Error loading notifications');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="notifications-page">
            <StaticNavHeader />
            <div className="notifications-container">
                {loading ? (
                    <div className="loading">Loading notifications...</div>
                ) : error ? (
                    <div className="error">{error}</div>
                ) : notifications.length === 0 ? (
                    <div className="no-notifications">
                        No notifications yet
                    </div>
                ) : (
                    notifications.map((notification) => (
                        <NotificationCard 
                            key={notification._id} 
                            notification={notification} 
                        />
                    ))
                )}
            </div>
            <Navbar />
        </div>
    );
};

export default NotificationsPage;
