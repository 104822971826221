import { store } from '../store';
import { backendDomain } from '../constants';

export const getAuthHeaders = () => {
    const token = store.getState().user.token;
    return token ? {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    } : {
        'Content-Type': 'application/json'
    };
};

export const fetchWithAuth = async (url, options = {}) => {
    const headers = getAuthHeaders();
    const response = await fetch(url, {
        ...options,
        headers: {
            ...headers,
            ...options.headers
        }
    });
    return response;
};

export const generateWordle = async (userId) => {
    const response = await fetchWithAuth(`${backendDomain}/wordle/generate-wordle`, {
        method: 'POST',
        body: JSON.stringify({ userId }),
    });
    return response.json();
};