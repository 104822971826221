import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { saveUser } from '../reducers/user';
import './css/ProfilePage.css'; // Import the CSS for styling
import Header from '../components/Header';
import UserOverview from '../components/UserOverview';
import GuessDistributionOverview from '../components/GuessDistributionOverview';
import FeedComponent from '../components/FeedComponent';
import { backendDomain } from '../constants';

const ProfilePageExternalView = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [userProfile, setUserProfile] = useState(null);
    const currentUser = useSelector(state => state.user);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            console.log("Already logged in");
            dispatch(saveUser(storedUser));
        }
        // Fetch user profile details from the server
        fetch(`${backendDomain}/user/fetch-user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id }),
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === 200) {
                    const fetchedUser = response.user;
                    setUserProfile(fetchedUser);
                    // Check if the fetched user is the logged-in user
                    if (currentUser && currentUser._id === fetchedUser._id) {
                        navigate('/profile');
                    };
                } else {
                    console.error('Failed to fetch user profile');
                }
            })
            .catch(error => {
                console.error('Error fetching user profile:', error);
            });
    }, [id, navigate, dispatch]);

    return (
        <>
            <Header />
            <div className="profile-page">
                {userProfile ? (
                    <div className="profile-content">
                        <UserOverview user={userProfile} />
                        <GuessDistributionOverview user={userProfile} />
                        <FeedComponent user={userProfile} isGlobal={false} isProfilePage={true} />
                    </div>
                ) : (
                    <div className="profile-content">
                        <h2>Loading profile...</h2>
                    </div>
                )}
            </div>
        </>
    );
};

export default ProfilePageExternalView;
