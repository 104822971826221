import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { saveUser } from '../reducers/user';
import AnimatedGreeting from './WordleGreeting';
import './css/WordleOverlayComponent.css'; // Import the CSS file

const WordleOverlayComponent = ({ user }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isVisible, setIsVisible] = useState(true);
    const [showLoginOverlay, setShowLoginOverlay] = useState(!user?._id);
    const [animationShown, setAnimationShown] = useState(false);
    const [playNowClicked, setPlayNowClicked] = useState(false);

    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        const animationDate = localStorage.getItem('animationDate');
        const playDate = localStorage.getItem('playDate');

        // Only consider playDate if user is logged in
        if (user?._id && playDate === today) {
            setPlayNowClicked(true);
        } else if (!user?._id) {
            // Clear playDate when user is not logged in
            localStorage.removeItem('playDate');
            setPlayNowClicked(false);
        }

        // Set animation shown if it's already been shown today
        if (animationDate === today) {
            setAnimationShown(true);
        }
    }, [user?._id]); // Add user._id as dependency to re-run when login status changes

    const handlePlayClick = () => {
        const today = new Date().toISOString().split('T')[0];
        localStorage.setItem('playDate', today);
        localStorage.setItem('animationDate', today); // Set animation date when play is clicked
        setPlayNowClicked(true);
        setIsVisible(false);
    };

    const onLoginSuccess = (response) => {
        const decoded = jwtDecode(response.credential);
        const userData = {
            firstName: decoded.given_name,
            lastName: decoded.family_name,
            emailId: decoded.email,
            imageUri: decoded.picture,
        };
        
        // Set animation date when user logs in
        const today = new Date().toISOString().split('T')[0];
        localStorage.setItem('animationDate', today);
        
        localStorage.setItem('user', JSON.stringify(userData));
        dispatch(saveUser(userData));
        setShowLoginOverlay(false);
        setAnimationShown(true); // Show without animation after login
    };

    // Don't render if play clicked (only for logged in users)
    if (!isVisible || (user?._id && playNowClicked)) {
        return null;
    }

    return (
        <div className="overlay">
            <div className="container">
                {!user?._id && (
                    <div className="icon">
                        <div className="grid">
                            {[...Array(9)].map((_, i) => (
                                <div key={i} className={`grid-cell ${i >= 3 && i <= 5 ? 'green-cell' : ''}`}></div>
                            ))}
                        </div>
                    </div>
                )}
                <AnimatedGreeting 
                    name={user?.firstName}
                    isLoggedIn={!showLoginOverlay}
                    onPlayClick={handlePlayClick}
                    onLoginSuccess={onLoginSuccess}
                    animationShown={animationShown}
                />
            </div>
        </div>
    );
};

export default WordleOverlayComponent;
