import { store } from '../store';
import { setToken, clearUser } from '../reducers/user';

export const initializeAuth = () => {
    const token = localStorage.getItem('token');
    if (token) {
        store.dispatch(setToken(token));
    }
};

export const clearAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    store.dispatch(clearUser());
};